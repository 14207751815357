<template>
  <div class="tableList">
    <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
     <global-table ref="addDetailtable" :tableField="tableField"  :tableData="orderData" ></global-table>
  </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { SearchList, SearchData, tableField } from './js/searchlist'
export default {
  name: 'OrderdialogBody',
  components: { ListSearch, GlobalTable },
  props: ['orderData'],
  data () {
    return {
      tableField: tableField,
      tableData: this.orderData,
      childData: { searchList: SearchList, searchData: SearchData },
      paginationData: {},
      pageNum: 1,
      pageSize: 10,
      searchdata: SearchData,
      id: ''
    }
  },
  methods: {
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      request('/api/order/order/getOrderInfoByUserId', 'POST', data).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records
        }
      })
    }
  }
}
</script>
<style scoped>
.tableList{
padding: 0px;
}
</style>
