export var SearchList = [
  { labe: '采购订单编号', code: 'orderCode', type: 'input' },
  { labe: '供应商', code: 'providerCode', type: 'input' }
]
export var SearchData = {
  sellOrderCode: '',
  providerCode: ''
}
export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '采购订单编号', code: 'orderCode', type: 'input', width: '' },
  { label: '供应商编号', code: 'providerCode', type: 'input', width: '' },
  { label: '供应商名称', code: 'providerName', type: 'input', width: '' }
]
export var tableData = []
